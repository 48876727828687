import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createForm } from 'rc-form';
import { setOrderInfo } from '../../actions/orderInfo';
import * as Api from '../../apis';
import Table from '../Table';
import Cell from '../Table/cell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';
import SelectLocation from '../SelectLocation';
import TimeSlotPicker from '../TimeSlotPicker';
import Link from '@material-ui/core/Link';
import { Select } from '@material-ui/core';
import useCollaboration from '../../hooks/useCollaboration';

const CheckoutForm = ({ title, form, slotPicker, delivery }) => {
  const dispatch = useDispatch();
  const { validateFields, getFieldDecorator, getFieldError } = form;
  const orderInfo = useSelector(state => state.orderInfo);
  const collaboration = useCollaboration(orderInfo.address);
  const [dialog, setDialog] = React.useState(false);
  
  async function fetchTimeSlot() {
    let dateSlots = {};
    if (slotPicker && orderInfo.address) {
      const latitude = orderInfo.address.latitude;
      const longitude = orderInfo.address.longitude;
      const data = await Api.getLabBranchTimeSlot(latitude, longitude);
      const timeSlots = data.results;
      if (timeSlots.length) {
        dispatch(setOrderInfo({
          medicallabBranch: timeSlots[0]?.medicallab_branch?.id,
        }));
      }
      timeSlots.forEach(timeSlot => {
        const slot = dateSlots[timeSlot.date] || [];
        slot.push(timeSlot);
        dateSlots[timeSlot.date] = slot;
      });
    }
    return dateSlots;
  }

  const onChange = (e) => {
    setTimeout(() => {
      validateFields((error, value) => {
        if (!error) {
          dispatch(setOrderInfo(value));
        }
      });
    }, 50);
  }

  return (
    <>
      <TimeSlotPicker
        noNote
        open={dialog}
        onClose={() => setDialog(false)}
        onConfirm={() => setDialog(false)}
        fetchTimeSlot={fetchTimeSlot}
      />
      { title && <h3>รายละเอียด</h3> }
      
      <Table>
        <TableBody>
          <TableRow>
            <Cell style={{ minWidth: '100px' }}>ชื่อ สกุล</Cell>
            <Cell>
              {
                getFieldDecorator('name', {
                  rules: [{ required: true }],
                  initialValue: orderInfo.name,
                })(
                  <Input
                    fullWidth
                    onChange={onChange}
                    error={getFieldError('name')}
                  />
                )
              }
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>เบอร์โทร.</Cell>
            <Cell>
              {
                getFieldDecorator('phone', {
                  rules: [{ required: true }],
                  initialValue: orderInfo.phone || (orderInfo.address && orderInfo.address.phone),
                })(
                  <Input
                    fullWidth
                    placeholder="090 999 9999"
                    type="tel"
                    onChange={onChange}
                    error={getFieldError('phone')}
                  />
                )
              }
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>ที่อยู่</Cell>
            <Cell>
              <SelectLocation force/>
            </Cell>
          </TableRow>
          <TableRow>
            <Cell>หมายเหตุ</Cell>
            <Cell>
              {
                getFieldDecorator('remark', {
                  initialValue: orderInfo.remark,
                })(
                  <Input
                    fullWidth
                    multiline
                    placeholder="เช่น บ้านเลขที่ 11/11"
                    onChange={onChange}
                    error={getFieldError('remark')}
                  />
                )
              }
            </Cell>
          </TableRow>
          {
            delivery && (
              <TableRow>
                <Cell>การจัดส่ง</Cell>
                <Cell>
                  {
                    getFieldDecorator('deliveryMethod', {
                      initialValue: orderInfo.deliveryMethod,
                    })(
                      <Select
                        native
                        onChange={onChange}
                        disabled={collaboration && !collaboration.within_the_range}
                      >
                        <option value="post_shipment" >ส่งปกติ 1-2 วัน</option>
                        <option value="delivery">ส่งแบบด่วน ภายใน 4 ชม.</option>
                      </Select>
                    )
                  }
                </Cell>
              </TableRow>
            )
          }
          {
            slotPicker && (
              <TableRow>
                <Cell>วันที่นัดหมาย</Cell>
                <Cell>
                  <Link color="secondary" onClick={e => {
                    e.preventDefault();
                    setDialog(true);
                  }}>{orderInfo.slotTimeText || 'เลือกวันที่และเวลา'}</Link>
                </Cell>
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </>

  );
}

export default createForm()(CheckoutForm);
