import React from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { createLoadingSelector } from '../../reducers/api';
import { subtotal } from '../../helpers/utility';
import { displayCurrency } from '../../helpers/currency';
import { createSelectedItemsSelector } from '../../reducers/lab';
import { checkoutLab, checkoutLabSummary, addLabItemId, removeLabItemId } from '../../actions/lab';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Layout from '../../components/Layout';
import CheckoutForm from '../../components/CheckoutForm';
import CheckoutItems from '../../components/CheckoutItems';
import CheckoutSummary from '../../components/CheckoutSummary';
import Payment from '../../components/Payment';
import Promotion from '../../components/Promotion';

export default () => {
  const [summary, setSummary] = React.useState();
  const dispatch = useDispatch();
  const { address, slotTimeId, promotionId, payment } = useSelector(state => state.orderInfo);
  const loading = useSelector(createLoadingSelector(['lab/order/summary/post', 'lab/order/post']));
  const currentSelected = useSelector(createSelectedItemsSelector);
  const subTotalPrice = subtotal(currentSelected.items);

  React.useEffect(() => {
    window.logEvent('begin_checkout', { currency: 'THB', value: subTotalPrice });
  }, []);

  React.useEffect(() => {
    if (address && currentSelected.items.length && slotTimeId) {
      fetchSummary();
    }
  }, [address, currentSelected.items.length, slotTimeId]);

  React.useEffect(() => {
    fetchSummary();
  }, [promotionId]);

  const fetchSummary = async () => {
    try {
      const res = await dispatch(checkoutLabSummary());
      setSummary(res);
    } catch (e) {
      setSummary();
      if (e.message && e.message.includes('address')) {
        alert('คุณอยู่นอกพื้นที่ให้บริการ');
      }
      if (e.message && e.message.includes('time_slot_id')) {
        alert('โปรดจองล่วงหน้าอย่างน้อยสองวัน');
      }
    }
  }

  const submit = async () => {
    try {
      const res = await dispatch(checkoutLab());
      const url = res.payment_redirect_uri;
      if (url) {
        if (url.includes('/scanPromptpay?')) {
          navigate(`/scanPromptpay?${url.split('/scanPromptpay?')[1]}`);
        } else if (url.includes('/mbanking?')) {
          navigate(`/mbanking?${url.split('/mbanking?')[1]}`);
        } else {
          navigate(`/result/cardpayment?uri=${url}`);
        }
      } else {
        throw 'error';
      }
    } catch (e) {
      alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
    }
  }

  const handleSelect = id => {
    dispatch(addLabItemId(id));
  };

  const handleDeselect = id => {
    dispatch(removeLabItemId(id));
  };

  const disabledButton = loading || !summary || !payment;

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>โปรแกรมตรวจสุขภาพ - MorOnline</title>
      </Helmet>
      <Box p={2}>
        <h2>สรุปการสั่งซื้อ</h2>
        <CheckoutItems order={currentSelected} onSelect={handleSelect} onDeselect={handleDeselect} />
        <CheckoutForm slotPicker />
        <Payment title />
        <Promotion title filter="lab" />
        <CheckoutSummary deliveryTitle="ค่าออกหน่วย" summary={summary}/>
        <Box mt={3}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={disabledButton}
            onClick={submit}
          >
            ชำระเงิน {summary && displayCurrency(summary.grand_total_price)}
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}

