import React from 'react';
import { displayCurrency } from '../../helpers/currency';
import { calculateByItem, subtotal } from '../../helpers/utility';
import Table from '../Table';
import Cell from '../Table/cell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ConfirmDialog from '../ConfirmDialog';

export default ({ readOnly, order, onSelect, onDeselect }) => {
  const [modal, setModal] = React.useState(false);
  const [currentId, setCurrentId] = React.useState(null);

  const handleSelect = id => () => {
    onSelect(id);
  };

  const handleDeselect = id => () => {
    if (order.items.find(m => m.product.id === id).quantity === 1) {
      setCurrentId(id);
      setModal(true);
    } else {
      onDeselect(id);
    }
  };

  const forceDeselect = () => {
    onDeselect(currentId);
    setCurrentId(null);
    setModal(false);
  };

  return (
    <Table>
      {
        !readOnly && (
          <ConfirmDialog
            open={modal}
            onConfirm={forceDeselect}
            onClose={() => setModal(false)}
            okText="ยืนยัน"
            cancelText="ยกเลิก"
            content="คุณสามารถเพิ่มรายการนี้ใหม่อีกครั้งในหน้าเลือกรายการ"
            title="ลบรายการนี้ออก?"
          />
        )
      }
      <TableHead>
        <TableRow>
          <Cell>สินค้า</Cell>
          <Cell align="center" style={{ minWidth: 90 }}>จำนวน</Cell>
          <Cell align="right">ราคา</Cell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          order.items.map((item, index) => (
            <TableRow key={index}>
              <Cell>
                <span>{item.product.name}</span><br />
              </Cell>
              <Cell align="center">
                {
                  !readOnly && (
                    <IconButton size="small" onClick={handleDeselect(item.product.id)}>
                      <RemoveIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
                {` ${item.quantity} `}
                {
                  !readOnly && (
                    <IconButton size="small" onClick={handleSelect(item.product.id)}>
                      <AddIcon fontSize="inherit" />
                    </IconButton>
                  )
                }
              </Cell>
              <Cell align="right">{displayCurrency(calculateByItem(item))}</Cell>
            </TableRow>
          ))
        }
        <TableRow>
          <Cell colSpan={1} align="right">
            ยอดสั่งซื้อ
          </Cell>
          <Cell colSpan={2} align="right">
            {displayCurrency(subtotal(order.items))}
          </Cell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

