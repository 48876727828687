import {
  renderAppointmentDateTime,
  parseAppointmentDateTime,
} from '../helpers/date';
import * as Constant from './constants';
import * as Api from '../apis';

export const postAddress = (address, latitude, longitude) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.ADDRESS_POST_REQUEST });

    const input = {
      address,
      name: address,
      latitude: parseFloat(latitude.toFixed(10)),
      longitude: parseFloat(longitude.toFixed(10)),
    };

    const { session, orderInfo } = getState();
    if (!session.user) {
      dispatch({ type: Constant.ADDRESS_POST_SUCCESS, payload: input });
      return Promise.resolve(input);
    }

    return Api.postAddress(orderInfo.address && orderInfo.address.id, input)
      .then(payload => {
        dispatch({ type: Constant.ADDRESS_POST_SUCCESS, payload });
        dispatch(getCollaboration(payload.latitude, payload.longitude));
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.ADDRESS_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const getAddresses = () => {
  return dispatch => {
    dispatch({ type: Constant.ADDRESSES_GET_REQUEST });
    return Api.getAddresses()
      .then(payload => {
        dispatch({ type: Constant.ADDRESSES_GET_SUCCESS, payload });
        const address = payload[0];
        if (address) {
          dispatch(getCollaboration(address.latitude, address.longitude));
        }
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.ADDRESSES_GET_FAILED, message });
      });
  };
};

export const getShippingPrice = (lat, lng, price) => {
  return dispatch => {
    return Api.getShippingPrice(lat, lng, price).then(payload => {
      dispatch({ type: Constant.SET_SHIPPING_PRICE, payload });
    });
  };
};

export const getCollaboration = (lat, lng, chatroom) => {
  return dispatch => {
    dispatch({ type: Constant.COLLABORATION_GET_REQUEST });
    return Api.getCollaboration(lat, lng, chatroom)
      .then(payload => {
        dispatch({ type: Constant.COLLABORATION_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.COLLABORATION_GET_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const setOrderInfo = payload => ({
  type: Constant.SET_ORDER_INFO,
  payload: payload,
});

export const setAppointmentSlot = (consultantId, slot) => {
  return {
    type: Constant.SET_ORDER_INFO,
    payload: {
      consultantId,
      slotTimeId: slot.id,
      slotTimeText: renderAppointmentDateTime(
        parseAppointmentDateTime(slot.date, slot.start_time),
      ),
    },
  };
};
