import React from 'react';
import { getCollaboration } from '../apis/index';
import { useSelector } from 'react-redux';
import { createTenantSelector } from '../reducers/orderInfo';

// Hook
export default function useCollaboration(address) {
  const tenant = useSelector(createTenantSelector);
  const [result, setResult] = React.useState(null);
  let latlng = `${address?.latitude}${address?.longitude}`;

  React.useEffect(() => {
    if (address) {
      getCollaboration(address.latitude, address.longitude, null, tenant).then(
        res => {
          setResult(res);
        },
      );
    }
  }, [latlng]);

  return result;
}
